
import { defineComponent, reactive, computed, onBeforeMount } from 'vue';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import floorTrakStore from '@/modules/floortrak/store/FloorTrakStore';
import router from '@/router';
import Shipment from '@/domain/Shipment';
import useMaxIntCompute from '@/composable/useMaxIntCompute';
import { CoreStore } from '@/store/CoreStore';
import SupplierShipmentService from '@/services/SupplierShipmentService';
import BForm from '@/components/bootstrap-library/BForm.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import Screen from '@/components/layout/Screen.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import { useNotification } from '@/composable/useNotifications';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';

type State = {
    searchValue: number | null;
    busy: boolean;
    showModal: boolean;
};

export default defineComponent({
    name: 'shipping-search',
    components: {
        Screen,
        BButton,
        BForm,
        BModal,
    },
    setup() {
        const shippingService = new SupplierShipmentService();
        const { shippingStore } = floorTrakStore.getInstance();
        const { profileStore } = CoreStore.getInstance();
        const { forkliftCertified, certExpirationDate } = CoreStore.getInstance().profileStore;

        const { valueLessThanMax } = useMaxIntCompute();
        const { showErrorList } = useNotification();
        const { confirm } = useDialogBox();

        const state = reactive<State>({
            searchValue: null,
            busy: false,
            showModal: true,
        });

        const disabled = computed((): boolean => {
            if (!state.searchValue) return true;
            if (!valueLessThanMax(state.searchValue)) return true;
            if (state.busy) return true;
            return false;
        });

        onBeforeMount(async () => {
            if (!forkliftCertified) {
                await confirm({
                    title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                    message: getTranslation('core.validation.forkliftCertificationMissing'),
                    vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            } else if (!certExpirationDate || new Date(certExpirationDate) < new Date(Date.now())) {
                await confirm({
                    title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                    message: getTranslation('core.validation.forkliftCertificationExpired'),
                    vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            }
        });

        function updateShipmentValues(shipment: Shipment) {
            shippingStore.resetTransactionArray();
            shippingStore.shipment = new Shipment(shipment);
            shippingStore.activeIndex = 0;
        }

        async function search() {
            if (!disabled.value && state.searchValue) {
                state.busy = true;
                const response = await shippingService.searchAndActivateTransaction(state.searchValue, profileStore.userLocation.id);
                if (response.success) {
                    updateShipmentValues(response.data);
                    await router.push({ name: FloorTrakRouteTypes.SHIPPING.EXISTING, params: { transactionNumber: state.searchValue } });
                } else if (response.errorList) {
                    showErrorList(Object.values(response.errorList));
                }
                state.busy = false;
            }
        }

        function closeSearch() {
            state.searchValue = null;
        }

        function escape() {
            closeSearch();
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        return {
            escape,
            state,
            search,
            disabled,
            getTranslation,
            getTitleCaseTranslation,
        };
    },
});
