import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    title: _ctx.getTitleCaseTranslation('core.common.searchShipments')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_modal, {
        modelValue: _ctx.state.showModal,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.showModal) = $event)),
        title: _ctx.getTitleCaseTranslation('core.common.searchShipments'),
        onHidden: _ctx.escape
      }, {
        "btn-ok": _withCtx(() => [
          _createVNode(_component_b_button, {
            variant: "primary",
            onClick: _ctx.search
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.search')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_b_form, { class: "search-form" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_text_input, {
                    modelValue: _ctx.state.searchValue,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.searchValue) = $event)),
                    placeholder: _ctx.getTitleCaseTranslation('core.common.search'),
                    disabled: _ctx.state.busy,
                    type: "number",
                    autofocus: ""
                  }, null, 8, ["modelValue", "placeholder", "disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "title", "onHidden"])
    ]),
    _: 1
  }, 8, ["title"]))
}